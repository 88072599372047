.loading-container {
    z-index: 999999 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.news-container {
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    height: 100px;
}

.news-card {
    width: 70%;
    height: auto;
    background-color: white;
    border-radius: 10px;
}

.groovoo-title {
    font-size: 2rem;
    font-weight: 500;
    color: #691392;
    margin-top: 20px;
}

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.div-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.groovoo-title span {
    font-size: 1.8rem;
}

.centraliza {
    display: flex;
    justify-content: center;
    align-items: center;
}

.justify-buttons{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#news-logo {
    width: 80px;
}

.card-content {
    height: 60%;
    margin-top: 10px;
    border-radius: 20px !important;
    padding: 0px 30px 30px 30px;
    color: #000;
}

#message {
    margin-top: 10px;
    color: #000;
}

#message span {
    font-weight: 600;
}

.card-footer {
    height: 20%;
    width: 90%;
    border-top: #691392 1px solid;
    background-color: white;
    text-align: center;
    padding: 20px;
}

.card-footer p {
    color: #000;
}

.footer-centraliza {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-modal {
    margin-top: 5px;
    font-size: 1.6rem;
    text-align: right;
    font-weight: 500;
    color: #691392;
    display: inline-block;
}

.close-modal:hover {
    margin-top: 5px;
    text-align: right;
    color: #691392;
    cursor: pointer;
}

@media (max-width: 768px) {
    .news-card {
        width: 90%;
    }
}


